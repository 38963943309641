import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  CookiesServiceInterface,
  COOKIES_SERVICE_TOKEN
} from './services/cookies';
@Component({
  selector: 'campus-cookies-content',
  templateUrl: './cookies-content.component.html',
  styleUrls: ['./cookies-content.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class CookiesContentComponent implements OnInit, OnDestroy, OnChanges {
  public cookieStatus: 'minimal' | 'current' | 'all';
  public cookies$: Observable<object>;
  public visible: boolean;
  private subscriptions = new Subscription();
  @Input() linkcookies: string;
  @Input() setVisible?: boolean;
  @Input() errormessage?: string;
  constructor(
    @Inject(COOKIES_SERVICE_TOKEN)
    private cookiesService: CookiesServiceInterface
  ) {}

  ngOnInit() {
    if (this.cookiesService.isLocalStorageEmpty()) {
      this.visible = true;
    }

    this.cookiesService.setInitialConsentValues();
    this.cookies$ = this.cookiesService.cookies$;

    this.subscriptions.add(
      this.cookies$.subscribe(cookies => {
        this.cookieStatus = this.getCookieStatus(cookies);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setVisible) {
      this.visible = this.setVisible;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public setConsentValue(label, checked) {
    this.cookiesService.setConsentValue(label, checked);
  }

  public setCookies(status = this.cookieStatus) {
    if (status === 'all') {
      this.cookiesService.setAllConsentValues(true);
    } else if (status === 'minimal') {
      this.cookiesService.setAllConsentValues(false);
    }
    this.cookiesService.saveConsentValues();
    window.location.reload(false);
    this.clearElement();
  }

  public clearElement() {
    this.visible = false;
    this.setVisible = false;
    this.errormessage = '';
  }

  private getCookieStatus(cookies) {
    return Object.values(cookies).some(value => value === true)
      ? 'current'
      : 'all';
  }
}
