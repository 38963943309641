import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import {
  MatButtonModule,
  MatIconModule,
  MatSlideToggleModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { CookiesContentComponent } from './cookies-content/cookies-content.component';
import {
  CookiesService,
  COOKIES_SERVICE_TOKEN
} from './cookies-content/services/cookies';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [CookiesContentComponent],
  entryComponents: [CookiesContentComponent],
  providers: [
    {
      provide: COOKIES_SERVICE_TOKEN,
      useClass: CookiesService
    }
  ]
})
export class CookiesConsentModule {
  constructor(injector: Injector) {
    const cookiesContentElement = createCustomElement(CookiesContentComponent, {
      injector: injector
    });

    customElements.define('campus-cookies-content', cookiesContentElement);
  }

  ngDoBootstrap() {}
}
