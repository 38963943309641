import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import 'zone.js';
import { CookiesConsentModule } from './lib/cookies-consent.module';

enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(CookiesConsentModule)
  .catch(err => console.error(err));
