import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookiesCategoriesInterface } from '../../interfaces/cookies-categories.interface';
import {
  CookiesServiceInterface,
  WINDOW_TOKEN
} from './cookies.service.interface';
@Injectable({
  providedIn: 'root'
})
export class CookiesService implements CookiesServiceInterface {
  private cookies: CookiesCategoriesInterface = {
    preferences: false,
    marketing: false,
    statistics: false,
    social_media: false
  };
  private _cookies$ = new BehaviorSubject<CookiesCategoriesInterface>(
    this.cookies
  );
  public cookies$ = this._cookies$ as Observable<CookiesCategoriesInterface>;
  private localStorageKey = 'cookies';

  constructor(
    @Inject(WINDOW_TOKEN)
    private window: Window
  ) {}

  public setInitialConsentValues() {
    const lsCookies = this.readLocalStorage();
    this._cookies$.next({
      ...this._cookies$.value,
      ...lsCookies
    });
  }

  public setConsentValue(label: string, checked: boolean) {
    this._cookies$.next({
      ...this._cookies$.value,
      [label]: checked
    });
  }

  public saveConsentValues() {
    this.writeLocalStorage(this._cookies$.value);
  }

  public setAllConsentValues(value: boolean) {
    this._cookies$.next(this.getAllConsentValues(value));
  }

  private getAllConsentValues(bool: boolean) {
    return Object.keys(this._cookies$.value).reduce(function(acc, obj) {
      acc[obj] = bool;
      return acc;
    }, {});
  }

  public isLocalStorageEmpty() {
    return Object.keys(this.readLocalStorage()).length === 0;
  }

  private readLocalStorage(key = this.localStorageKey) {
    const cookies = this.window.localStorage.getItem(key);
    if (!cookies) {
      return {};
    }
    return JSON.parse(cookies);
  }

  private writeLocalStorage(value, key = this.localStorageKey) {
    this.window.localStorage.setItem(key, JSON.stringify(value));
  }
}
